import React, { useEffect, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import _ from "lodash";
import { ChakraProvider, Flex, extendTheme } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import { getOrganizations } from "./store/actions/defaultAction";

import Layout from "./pages/Layout";
import Dashboard from "./pages/Dashboard";

//branch
import BranchNPSRatingPerAgent from "./pages/branches/BranchNPSRatingPerAgent";
import BranchNPSRating from "./pages/branches/BranchNPSRating";
import BranchRatingsBreakdown from "./pages/branches/BranchRatingsBreakDown";
import BranchFeedbackEngagement from "./pages/branches/BranchFeedbackEngagement";

//organization
import OrganizationNPSRating from "./pages/organization/OrganizationNPSRating";
import OrganizationRatingsBreakdown from "./pages/organization/OrganizationRatingsBreakdown";
import OrganizationFeedbackEngagement from "./pages/organization/OrganizationFeedbackEngagement";
import OrganizationNPSRatingPerAgent from "./pages/organization/OrganizationNPSRatingPerAgent";

// end monthly reports

//No permissions
import NoPermissions from "./pages/NoPermissions";

// import css
import "./assets/css/All.css";
import "./assets/css/Print.css";
import { getForm, setRatingsLoading } from "./store/actions/ratingsActions";
import Loader from "./components/loader";
import usePermissions from "./hooks/usePermissions";
import {
  updateAppStatus,
  verifyToken,
} from "./store/actions/authActions";
import { getIamRoles } from "./store/actions/permissionsActions";

function App() {
  const { read } = usePermissions();
  const dispatch = useDispatch();
  const { appStatus, verifyStatus, userId, userData } = useSelector(
    (state) => state.authReducer
  );
  const { organizationId } = useSelector((state) => state.defaultReducer);
  useEffect(() => {
    if (appStatus) {
      dispatch(getOrganizations());
    }
  }, [appStatus]);

  useEffect(() => {
    dispatch(verifyToken());
    dispatch(setRatingsLoading(false));
  }, []);

  useEffect(() => {
    if (userId && verifyStatus === 1) {
      dispatch(getIamRoles());
    }
  }, [userId, verifyStatus]);

  useEffect(() => {
    dispatch(getForm(organizationId));
  }, [organizationId]);

  useEffect(() => {
    if (read && verifyStatus === 1) {
      dispatch(updateAppStatus(1));
    } else {
      dispatch(updateAppStatus(0));
    }
  }, [read, verifyStatus]);

  const theme = extendTheme({
    fonts: {
      body: "Lato, sans-serif",
      heading: "Lato, sans-serif",
    },
  });

  return (
    <ChakraProvider theme={theme}>
      {!appStatus ? (
        <>{!read && !_.isEmpty(userData) ? <NoPermissions /> : <Loader />}</>
      ) : (
        <Router>
          <Layout>
            <ToastContainer position="bottom-center" />
            <Suspense
              fallback={
                <Flex justify="center" align="center" h="100vh">
                  <Loader />
                </Flex>
              }
            >
              <Routes>
                {/* Monthly*/}
                <Route path="/" element={<Dashboard />} />
                <Route
                  path="/monthly/branches/nps-rating-per-agent"
                  element={<BranchNPSRatingPerAgent />}
                />
                <Route
                  path="/monthly/comparison/branches/nps-rating-per-agent"
                  element={<BranchNPSRatingPerAgent isComparison={true} />}
                />
                <Route
                  path="/monthly/branches/nps-rating"
                  element={<BranchNPSRating />}
                />
                <Route
                  path="/monthly/comparison/branches/nps-rating"
                  element={<BranchNPSRating isComparison={true} />}
                />
                <Route
                  path="/monthly/branches/ratings-breakdown"
                  element={<BranchRatingsBreakdown />}
                />
                <Route
                  path="/monthly/comparison/branches/ratings-breakdown"
                  element={<BranchRatingsBreakdown isComparison={true} />}
                />

                <Route
                  path="/monthly/branches/feedback-engagement"
                  element={<BranchFeedbackEngagement />}
                />
                <Route
                  path="/monthly/comparison/branches/feedback-engagement"
                  element={<BranchFeedbackEngagement isComparison={true} />}
                />
                {/* Organization */}
                <Route
                  path="/monthly/organization/nps-rating"
                  element={<OrganizationNPSRating />}
                />
                <Route
                  path="/monthly/comparison/organization/nps-rating"
                  element={<OrganizationNPSRating isComparison={true} />}
                />
                <Route path="*" element={<Navigate to="/" replace />} />

                <Route
                  path="/monthly/organization/ratings-breakdown"
                  element={<OrganizationRatingsBreakdown />}
                />
                <Route
                  path="/monthly/comparison/organization/ratings-breakdown"
                  element={<OrganizationRatingsBreakdown isComparison={true} />}
                />
                <Route
                  path="/monthly/organization/feedback-engagement"
                  element={<OrganizationFeedbackEngagement />}
                />
                <Route
                  path="/monthly/comparison/organization/feedback-engagement"
                  element={
                    <OrganizationFeedbackEngagement isComparison={true} />
                  }
                />
                <Route
                  path="/monthly/organization/nps-rating-per-agent"
                  element={<OrganizationNPSRatingPerAgent />}
                />
                <Route
                  path="/monthly/comparison/organization/nps-rating-per-agent"
                  element={
                    <OrganizationNPSRatingPerAgent isComparison={true} />
                  }
                />
              </Routes>
            </Suspense>
          </Layout>
        </Router>
      )}
    </ChakraProvider>
  );
}

export default App;
