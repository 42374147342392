import { Flex, Box } from "@chakra-ui/react";
import Sidebar from "../components/Sidebar";

const Layout = (props) => {
  return (
    <Box>
      {/* Sidebar */}
      <Sidebar />

      {/* Content Area */}
      <Box className="content" p={4} overflowY="auto">
        {/* render children */}
        {/* do not render below on dashboard route */}
        {props.children}
      </Box>
    </Box>
  );
};

export default Layout;
