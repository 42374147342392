import { Box, Table, Tag, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import {formatNumber} from "../../../store/helpers"

const NPSRatingPerAgentTable = ({
  index,
  branchIndex,
  reportIndex,
  branchName,
  agentData,
}) => {
  const { agentName, agentRatings, totalRatings } = agentData;
  const title = `${reportIndex || ""}.${branchIndex + 1}.${
    index + 1
  }. ${agentName}`;
  return (
    <Box className="reportContainer" mt="20px">
      <h3>{title}</h3>
      <Tag>
        <p className="branchTag">{branchName} Branch</p>
      </Tag>
      {totalRatings ? (
        <>
          {" "}
          <Table className="stripedTable" size="sm">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th borderRight="1px solid #dadce0">Rating</Th>
                <Th borderRight="1px solid #dadce0">Average</Th>
                <Th borderRight="1px solid #dadce0">Min</Th>
                <Th borderRight="1px solid #dadce0">Max</Th>
                <Th borderRight="1px solid #dadce0">Promoters</Th>
                <Th>Passive</Th>
                <Th>Detractors</Th>
                <Th>NPS Rating</Th>
              </Tr>
            </Thead>
            <Tbody>
              {agentRatings.map((data, index) => {
                const {
                  totalRatings,
                  promoters,
                  detractors,
                  passives,
                  NPSScore,
                  promotersPct,
                  detractorsPct,
                  passivesPct,
                  ratingId,
                  ratingName,
                  average,
                  min,
                  max,
                } = data;
                return (
                  <Tr key={ratingId}>
                    <Td>{index + 1}</Td>
                    <Td borderRight="1px solid #dadce0">
                      <Text>{ratingName}</Text>
                    </Td>
                    <Td borderRight="1px solid #dadce0">
                      <Text>{formatNumber(average)}</Text>
                    </Td>
                    <Td borderRight="1px solid #dadce0">
                      <Text>{formatNumber(min)}</Text>
                    </Td>
                    <Td borderRight="1px solid #dadce0">
                      <Text>{formatNumber(max)}</Text>
                    </Td>
                    <Td borderRight="1px solid #dadce0">
                      {" "}
                      <Text color={"var(--green)"}>
                        {formatNumber(promoters)}
                        <Box
                          color="gray.500"
                          fontWeight="semibold"
                          fontSize="xs"
                          textTransform="uppercase"
                        >
                          ({promotersPct.toFixed(2)}%)
                        </Box>
                      </Text>
                    </Td>

                    <Td>
                      {" "}
                      <Text>
                        {formatNumber(passives)}
                        <Box
                          color="gray.500"
                          fontWeight="semibold"
                          fontSize="xs"
                          textTransform="uppercase"
                        >
                          ({passivesPct.toFixed(2)}%)
                        </Box>
                      </Text>
                    </Td>

                    <Td>
                      {" "}
                      <Text color={"var(--red)"}>
                        {formatNumber(detractors)}
                        <Box
                          color="gray.500"
                          fontWeight="semibold"
                          fontSize="xs"
                          textTransform="uppercase"
                        >
                          ({detractorsPct.toFixed(2)}%)
                        </Box>
                      </Text>
                    </Td>

                    <Td borderRight="1px solid #dadce0">
                      <Text>{NPSScore.toFixed(2)}%</Text>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </>
      ) : (
        <>
          {" "}
          <Text fontSize="sm">No data available for this report</Text>
        </>
      )}
    </Box>
  );
};

export default NPSRatingPerAgentTable;
