import { ACTION_TYPES } from "../actions/types";

const initialState = {
  iamRoles: [],
  permissions: [],
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.GET_IAM_ROLES:
      return {
        ...state,
        iamRoles: action.payload,
      };

    case ACTION_TYPES.GET_IAM_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };
    default:
      return state;
  }
}
