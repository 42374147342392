import axios from "axios";
import { ACTION_TYPES } from "./types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APP_STATUSES } from "../constants";
import Cookies from "js-cookie";

axios.defaults.baseURL = "https://cx.api.averly.com.na";

export const authenticateUser =
  ({ email, password }) =>
  async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_AUTH_LOADING, payload: true });
    try {
      const res = await axios.post("/auth/authenticate", {
        email,
        password,
      });
      const userData = res.data;
      dispatch({
        type: ACTION_TYPES.UPDATE_APP_STATUS,
        payload: APP_STATUSES.LOGGED_IN,
      });
    } catch (err) {
      toast.error("Invalid Email or Password");
    }
    dispatch({ type: ACTION_TYPES.SET_AUTH_LOADING, payload: false });
  };

export const updateAppStatus = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_APP_STATUS,
    payload,
  });
};

export const verifyToken = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPES.SET_AUTH_LOADING, payload: true });
  const accessToken = Cookies.get("accessToken");
  const userId = Cookies.get("userId");
  try {
    const res = await axios.get(`/auth/verification?userId=${userId}`, {
      headers: {
        Authorization: `Bearer ${String(accessToken)}`,
      },
    });
    dispatch({ type: ACTION_TYPES.SET_USER_DATA, payload: res.data.user });
    dispatch({
      type: ACTION_TYPES.GET_USER_PERMISSIONS,
      payload: res.data.permissions,
    });
    dispatch({
      type: ACTION_TYPES.SET_USER_ID,
      payload: Cookies.get("userId"),
    });

    dispatch({
      type: ACTION_TYPES.UPDATE_VERIFY_STATUS,
      payload: 1,
    });
  } catch (err) {
    dispatch({
      type: ACTION_TYPES.UPDATE_VERIFY_STATUS,
      payload: 0,
    });

    window.location.replace(
      `https://auth.averly.com.na?origin=${window.location.href}`
    );
  }
  dispatch({ type: ACTION_TYPES.SET_AUTH_LOADING, payload: false });
};
