import { useSelector } from "react-redux";
import {
  generateDateArray,
  generatePrevMonthsArray,
} from "../../../store/helpers";
import dayjs from "dayjs";
import NPSRatingTable from "../tables/NPSRatingTable";
import { Box, Text } from "@chakra-ui/react";
import _ from "lodash";

const NPSRating = ({ data, reportIndex, isComparison }) => {
  const { branches, dateRange, workWeek, organizationId, branchId } =
    useSelector((state) => state.defaultReducer);
  const { form } = useSelector((store) => store.ratingsReducer);

  const branchesClone = _.cloneDeep(branches);
  const orgBranches = branchesClone
    .filter(
      (a) => a.organizationId === organizationId && Number(a.cfReportStatus)
    )
    .filter((a) => !branchId.length || (branchId.length && a.id === branchId));
  const branchesMap = orgBranches.reduce((acc, branch) => {
    acc[branch.id] = [];
    return acc;
  }, {});

  data.forEach((a) => {
    const branchId = a.branchId;

    if (Array.isArray(branchesMap[branchId])) {
      branchesMap[branchId].push(a);
    }
  });

  const branchesData = Object.keys(branchesMap)
    .map((branchId) => {
      const branchData = branchesMap[branchId];

      // All form inputs of type rating
      const ratingsMap = form.fields
        .filter((a) => a.type === "rating")
        .reduce((acc, { id }) => {
          acc[id] = [];
          return acc;
        }, {});

      branchData.forEach(({ ratings, created }) => {
        ratings.forEach((a) => {
          if (Array.isArray(ratingsMap[a.id])) {
            ratingsMap[a.id].push({ ...a, created });
          }
        });
      });
      const branchRatingsData = Object.keys(ratingsMap)
        .map((ratingId) => {
          const ratingData = ratingsMap[ratingId];
          const ratingName = form.fields.find(
            (a) => a.id === ratingId
          )?.shortLabel;

          //monthly chart

          let chart = [];
          if (isComparison) {
            const monthsArray = generatePrevMonthsArray(dateRange[0]);
            const monthlyChart = monthsArray.map((monthStart) => {
              const monthData = ratingData.filter(
                (a) =>
                  dayjs.unix(a.created).format("MM-YYYY") ===
                  dayjs(monthStart).format("MM-YYYY")
              );
              const promoters = monthData.filter((a) => a.value >= 9).length;
              const detractors = monthData.filter((a) => a.value <= 6).length;
              const promotersPct = (promoters / monthData.length) * 100 || 0;
              const detractorsPct = (detractors / monthData.length) * 100 || 0;

              const nps = promotersPct - detractorsPct || 0;
              return {
                date: dayjs(monthStart).format("MM-YYYY"),
                nps: Number(nps.toFixed(2)),
              };
            });
            chart = monthlyChart;
          } else {
            const datesBetween = generateDateArray(dateRange[0], dateRange[1]);
            const dailyChart = datesBetween
              .map((fullDate) => {
                let date = dayjs(fullDate).date();
                const dayData = ratingData.filter(
                  (a) => dayjs.unix(a.created).date() === date
                );
                const promoters = dayData.filter((a) => a.value >= 9).length;
                const detractors = dayData.filter((a) => a.value <= 6).length;
                const promotersPct = (promoters / dayData.length) * 100;
                const detractorsPct = (detractors / dayData.length) * 100;

                const nps = promotersPct - detractorsPct || 0;
                if (workWeek.includes(dayjs(fullDate).day())) {
                  return {
                    date,
                    nps: Number(nps.toFixed(2)),
                  };
                }
                return null;
              })
              .filter(Boolean);
            chart = dailyChart;
          }

          const min = Math.min(...chart.map((a) => a.nps)) || 0;
          const max = Math.max(...chart.map((a) => a.nps)) || 0;
          //sum nps
          const sum = chart.reduce((a, b) => a + Number(b.nps), 0);
          const average = sum / chart.length || 0;

          return {
            totalRatings: ratingData.length,
            ratingId,
            ratingName,
            average,
            min,
            max,
            chart,
            chartTitle: isComparison
              ? `${ratingName}, Month by Month comparison`
              : `${ratingName}, Day by Day Comparison`,
          };
        })
        .sort((a, b) => b.totalRatings - a.totalRatings);

      return {
        branchRatingsData,
        totalBranchRatings:
          branchRatingsData.reduce((acc, a) => acc + a.totalRatings, 0) || 0,
        branchName: branches.find((a) => a.id === branchId)?.name,
        branchId,
      };
    })
    .sort((a, b) => b.totalBranchRatings - a.totalBranchRatings);

  return (
    <Box>
      {branchesData.map(
        ({ branchId, branchName, branchRatingsData }, branchIdex) => {
          const title = `${branchName} Branch`;
          return (
            <Box key={branchId}>
              <h2>{title}</h2>
              <Text>
                {branchRatingsData.length} rating
                {branchRatingsData.length !== 1 ? "s" : ""}
              </Text>
              {branchRatingsData.length === 0 && (
                <Text>No data available for this branch</Text>
              )}
              {branchRatingsData.map((data, index) => {
                return (
                  <NPSRatingTable
                    key={`${data.ratingId}${branchId}`}
                    ratingData={data}
                    branchIndex={branchIdex}
                    index={index}
                    branchName={branchName}
                    reportIndex={reportIndex}
                  />
                );
              })}
            </Box>
          );
        }
      )}
    </Box>
  );
};

export default NPSRating;
