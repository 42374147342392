import { Box, Heading } from "@chakra-ui/react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const RatingsBreakdownBarChart = ({ chart, title }) => {
  return (
    <>
      <Box width="100%" height="300px">
        <ResponsiveContainer>
          <BarChart
            data={chart}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid stroke="#EBEBEB" vertical={false} />
            <XAxis
              dataKey="date"
              name="Day"
              axisLine={{ stroke: "var(--darkGrey)", strokeWidth: 1 }}
            />
            <YAxis axisLine={{ stroke: "var(--darkGrey)", strokeWidth: 1 }} />
            <Tooltip />
            <Legend width="100%" />
            <Bar
              dataKey="detractors"
              name="Detractors"
              fill={"var(--red)"}
              stackId="a"
            />
            <Bar
              dataKey="passives"
              name="passives"
              fill={"var(--orange)"}
              stackId="a"
            />
            <Bar
              dataKey="promoters"
              name="Promoters"
              fill={"var(--green)"}
              stackId="a"
            />
          </BarChart>
        </ResponsiveContainer>
        <Heading
          size="sm"
          fontWeight={400}
          fontSize="14px"
          color="#535353"
          mb="20px"
          textAlign="center"
        >
          {title}
        </Heading>
      </Box>
    </>
  );
};

export default RatingsBreakdownBarChart;
