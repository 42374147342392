import {
  Box,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { formatNumber } from "../../../store/helpers";
import FeedbackEngagementLineChart from "../charts/FeedbackEngagementLineChart";

const FeedbackEngagementTable = ({
  index,
  branchIndex,
  reportIndex,
  branchName,
  data,
}) => {
  const {
    chart,
    chartTitle,
    //feedback
    totalFeedback,
    minFeedback,
    maxFeedback,
    avgFeedback,
    //visitors
    totalVisitors,
    minVisitors,
    maxVisitors,
    avgVisitors,
  } = data;
  const title = `${reportIndex || ""}.${branchIndex + 1}.${
    index + 1
  }. ${"All Ratings"}`;
  return (
    <Box className="reportContainer" mt="20px">
      <h3>{title}</h3>
      <Tag>
        <p className="branchTag">{branchName} Branch</p>
      </Tag>
      {totalVisitors ? (
        <>
          {" "}
          <Table className="stripedTable" size="sm">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th borderRight="1px solid #dadce0">Type</Th>
                <Th borderRight="1px solid #dadce0">Total</Th>
                <Th borderRight="1px solid #dadce0">Average</Th>
                <Th borderRight="1px solid #dadce0">Min</Th>
                <Th borderRight="1px solid #dadce0">Max</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{index + 1}</Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>Visitors</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{formatNumber(totalVisitors)}</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{formatNumber(avgVisitors)}</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{formatNumber(minVisitors)}</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{formatNumber(maxVisitors)}</Text>
                </Td>
              </Tr>

              <Tr>
                <Td>{index + 2}</Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>Feedback</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{formatNumber(totalFeedback)}</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{formatNumber(avgFeedback)}</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{formatNumber(minFeedback)}</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{formatNumber(maxFeedback)}</Text>
                </Td>
              </Tr>
              <Tr>
                <Td colSpan={6}>
                  <Box mt="20px" mb="20px">
                    <Box key={3423}>
                      <FeedbackEngagementLineChart
                        chart={chart}
                        title={chartTitle}
                      />
                    </Box>
                  </Box>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </>
      ) : (
        <>
          {" "}
          <Text fontSize="sm">No data available for this report</Text>
        </>
      )}
    </Box>
  );
};

export default FeedbackEngagementTable;
