import dayjs from "dayjs";
import { NUM_PREV_MONTHS, REPORT_RANGES } from "./constants";

// loop through services and add a property to each service object that indicates if the service has children or not
export const checkServiceHasChildren = (services) => {
  return services.map((a) => {
    a.hasChildren = services.filter((b) => b.parent === a.id).length ? 1 : 0;
    return a;
  });
};

// loop through ticket array and add a property to each ticket object that indicates if the service exists or not
export const checkServiceExistsForTickets = (tickets, services) => {
  return tickets.map((a) => {
    a.serviceExistStatus = services.find((b) => b.id === a.serviceId) ? 1 : 0;
    return a;
  });
};

// find all services that have been delete but were present in the tickets array
export const findDeletedServices = (ticketsUpdated) => {
  return ticketsUpdated
    .map((a) => {
      return !a.serviceExistStatus ? a : null;
    })
    .filter(Boolean);
};

export const getReadableKeyFromObject = (obj, value) => {
  let key = Object.keys(obj).find((key) => obj[key] === value);
  return key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
};

export const getNonWorkDays = (workWeek) => {
  return [0, 1, 2, 3, 4, 5, 6].filter((a) => !workWeek.includes(a));
};

export const getNonWorkDates = (date, nonWorkDays, daysInMonth) => {
  const nonWorkDates = [];
  for (let dayIndex = 1; dayIndex <= daysInMonth; dayIndex++) {
    const currentDay = date.date(dayIndex);
    if (nonWorkDays.includes(currentDay.day())) {
      nonWorkDates.push(currentDay.format("D"));
    }
  }
  return nonWorkDates;
};

//
export const mapHasData = (map) => {
  const maxLength = Math.max(...Object.values(map).map((arr) => arr.length));

  return maxLength > 0;
};

export const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600).toFixed(0);
  const minutes = Math.floor((seconds % 3600) / 60).toFixed(0);
  const remainingSeconds = (seconds % 60).toFixed(0);
  const formattedTime = `${padZero(hours)}:${padZero(minutes)}:${padZero(
    remainingSeconds
  )}`;
  return formattedTime;
};
function padZero(num) {
  return num < 10 ? `0${num}` : num;
}

export const formatNumber = (number) => {
  return number.toLocaleString();
};

//generate dates array between provided timestamps
export const generateDateArray = (startTimestamp, endTimestamp) => {
  const startDate = dayjs.unix(startTimestamp);
  const endDate = dayjs.unix(endTimestamp);

  const datesArray = [];
  let currentDate = startDate;

  while (currentDate.isBefore(endDate)) {
    datesArray.push(currentDate.format("MM-DD-YYYY"));
    currentDate = currentDate.add(1, "day");
  }

  return datesArray;
};

export const generatePrevMonthsArray = (selectedTimestamp) => {
  const monthsArray = [];
  let currentDate = dayjs.unix(selectedTimestamp).startOf("month");
  let startMonth = currentDate.subtract(NUM_PREV_MONTHS, "month");
  while (startMonth.isBefore(currentDate)) {
    monthsArray.push(startMonth.startOf("month").format("MM-DD-YYYY"));
    startMonth = startMonth.add(1, "month");
  }

  return monthsArray;
};

//compare two arrays
export const arraysAreEqual = (arr1, arr2) => {
  return (
    arr1.length === arr2.length && arr1.every((elem, i) => elem === arr2[i])
  );
};

export const getReportDescription = (reportRange) => {
  const startStr =
    {
      [REPORT_RANGES.DAY]: "Daily",
      [REPORT_RANGES.WEEK]: "Weekly",
      [REPORT_RANGES.MONTH]: "Monthly",
    }[reportRange] || "Monthly";

  return Object.freeze({
    Tickets: `${startStr} Tickets overview of the entire organization. Total tickets, average tickets per day, minimum and maximum tickets recorded for the ${reportRange}. Including a line-chart with a day to day comparison of total tickets, completed tickets, no-show and unattended tickets.`,
    TicketsPerService: `${startStr} Tickets Service-by-Service breakdown of the entire organization. Total tickets, average tickets per day, minimum and maximum tickets recorded for the ${reportRange} for each service. Including a Pie-Chart for the monthly comparison of Completed, No-show and unattended tickets and a Line-chart a line-chart with a day to day comparison of total tickets, completed tickets, no-show and unattended tickets per service`,
    ServiceTimePerService: `${startStr} Service Time, Service-by-Service breakdown of the entire organization. Total completed tickets, Total Service Time, Average Service Time per ticket, minimum and maximum service times recorded for the ${reportRange} for each service. Including a line-chart with a day to day comparison of Average Service Time per Day.`,
    ArrivalRatesPerHour: `${startStr} overview of the Arrival rates per hour of the entire organization. Total customers /${reportRange}, average customer per hour, minimum and maximum customers per hour. Including a line-chart with a day to day comparison of total customers per hour and Time-of-Day Chart indicate customer arrival time for every day of the week.`,
    ArrivalRatesPerDay: `${startStr} overview of the Arrival rates per day of the entire organization. Total customers /${reportRange}, average customer per day, minimum and maximum customers per day. Including a line-chart with a day to day comparison of total customers per day.`,
    ArrivalRatesByService: `${startStr} overview of the Arrival rates per day per service. Total customers /${reportRange}, average customer per day, minimum and maximum customers per day per service. Including a line-chart with a day to day comparison of total customers per service.`,
    TicketsByBranch: `${startStr} overview of Tickets Per Branch. Total customers /${reportRange}, average customer per day, minimum and maximum customers per day per service. Including a line-chart with a day to day comparison of total customers per branch.`,
    BranchesArrivalRatesPerHour: `${startStr} overview of hourly customer visits per branch. Total customers /${reportRange}, average customers per hour, minimum and maximum customers per hour, for the ${reportRange}. Including a line-chart with a day to day comparison of total customers per hour and a Time-of-Day Chart indicating customer arrival times for every day of the week.`,
    BranchesArrivalRatesPerDay: `${startStr} overview of daily customer visits per branch. Total customers /${reportRange}, average customers per day, minimum and maximum customers per day, for the ${reportRange}. Including a line-chart with a day to day comparison of total customers per day.`,
    BranchesArrivalRatesPerService: `${startStr} overview of the frequency of demand for specific service per branch. Total customers /${reportRange}, average customers per day, minimum and maximum customers per day, for the ${reportRange}. Including a line-chart with a day to day comparison of total customers per service.`,
    ServiceTimePerServiceBranches: `${startStr} overview of the total time to complete specific service per branch. Total customers /${reportRange}, average customer per day, minimum and maximum customers per day. Including a line-chart with a day to day comparison of total customers per service.`,
    AgentServiceSessionTime: `${startStr} overview of total time agent took to complete particular service per branch. Total customers /${reportRange}, average customers per day, minimum and maximum customers per day, for the ${reportRange}. Including a line-chart with a day to day comparison of total customers per service.`,
    TicketsPerServiceBranches: `${startStr} overview of number of tickets issued for a service per branch. Total tickets /${reportRange}, average tickets per day, minimum and maximum tickets per day, for the ${reportRange}. Including a line-chart with a day to day comparison of total tickets per service and per branch.`,
    TicketsByAgents: `${startStr} overview of number of tickets attended by specific agent per branch. Total tickets /${reportRange}, average tickets per day, minimum and maximum tickets per day, for the ${reportRange}. Including a line-chart with a day to day comparison of total tickets per agent, per branch.`,
    AgentTicketsByService: `${startStr} breakdown of number of tickets attended by specific agent for specific service per branch. Total tickets /${reportRange}, average tickets per day, minimum and maximum tickets per day, for the ${reportRange}. Including a line-chart with a day to day comparison of total customers per service.`,
  });
};
