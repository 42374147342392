import { useSelector } from "react-redux";
import {
  generateDateArray,
  generatePrevMonthsArray,
} from "../../../store/helpers";
import dayjs from "dayjs";
import RatingsBreakdownTable from "../tables/RatingsBreakdownTable";
import { Box, Text } from "@chakra-ui/react";
import _ from "lodash";

const RatingsBreakdown = ({ data, reportIndex, isComparison }) => {
  const { branches, dateRange, workWeek, branchId, organizationId } =
    useSelector((state) => state.defaultReducer);
  const { form } = useSelector((store) => store.ratingsReducer);

  const branchesClone = _.cloneDeep(branches);
  const orgBranches = branchesClone
    .filter(
      (a) => a.organizationId === organizationId && Number(a.cfReportStatus)
    )
    .filter((a) => !branchId.length || (branchId.length && a.id === branchId));
  const branchesMap = orgBranches.reduce((acc, branch) => {
    acc[branch.id] = [];
    return acc;
  }, {});

  data.forEach((a) => {
    const branchId = a.branchId;

    if (Array.isArray(branchesMap[branchId])) {
      branchesMap[branchId].push(a);
    }
  });

  const branchesData = Object.keys(branchesMap)
    .map((branchId) => {
      const branchData = branchesMap[branchId];
      // All form inputs of type rating
      const ratingsMap = form.fields
        .filter((a) => a.type === "rating")
        .reduce((acc, { id }) => {
          acc[id] = [];
          return acc;
        }, {});

      branchData.forEach(({ ratings, created }) => {
        ratings.forEach((a) => {
          if (Array.isArray(ratingsMap[a.id])) {
            ratingsMap[a.id].push({ ...a, created });
          }
        });
      });
      const branchRatingsData = Object.keys(ratingsMap).map((ratingId) => {
        const ratingData = ratingsMap[ratingId];
        const ratingName = form.fields.find(
          (a) => a.id === ratingId
        )?.shortLabel;

        // chart
        let chart = [];

        if (isComparison) {
          const monthsArray = generatePrevMonthsArray(dateRange[0]);
          const monthlyChart = monthsArray.map((monthStart) => {
            const monthData = ratingData.filter(
              (a) =>
                dayjs.unix(a.created).format("MM-YYYY") ===
                dayjs(monthStart).format("MM-YYYY")
            );
            const promoters = monthData.filter((a) => a.value >= 9).length;
            const detractors = monthData.filter((a) => a.value <= 6).length;
            const passives = monthData.filter(
              (a) => a.value >= 7 && a.value <= 8
            ).length;
            return {
              date: dayjs(monthStart).format("MM-YYYY"),
              promoters,
              detractors,
              passives,
              total: monthData.length,
            };
          });
          chart = monthlyChart;
        } else {
          const datesBetween = generateDateArray(dateRange[0], dateRange[1]);
          const dailyChart = datesBetween
            .map((fullDate) => {
              let date = dayjs(fullDate).date();
              const dayData = ratingData.filter(
                (a) => dayjs.unix(a.created).date() === date
              );
              const promoters = dayData.filter((a) => a.value >= 9).length;
              const detractors = dayData.filter((a) => a.value <= 6).length;
              const passives = dayData.filter(
                (a) => a.value >= 7 && a.value <= 8
              ).length;
              if (workWeek.includes(dayjs(fullDate).day())) {
                return {
                  date,
                  promoters,
                  detractors,
                  passives,
                  total: dayData.length,
                };
              }
              return null;
            })
            .filter(Boolean);
          chart = dailyChart;
        }

        //promoters
        const promotersMin = Math.min(...chart.map((a) => a.promoters));
        const promotersMax = Math.max(...chart.map((a) => a.promoters));
        const promotersSum = chart.reduce((a, b) => a + Number(b.promoters), 0);
        const promotersAverage = promotersSum / chart.length;
        const totalPromoters = chart.reduce(
          (a, b) => a + Number(b.promoters),
          0
        );

        //detactors
        const detractorsMin = Math.min(...chart.map((a) => a.detractors));
        const detractorsMax = Math.max(...chart.map((a) => a.detractors));
        const detractorsSum = chart.reduce(
          (a, b) => a + Number(b.detractors),
          0
        );
        const detractorsAverage = detractorsSum / chart.length;
        const totalDetractors = chart.reduce(
          (a, b) => a + Number(b.detractors),
          0
        );

        //passives
        const passivesMin = Math.min(...chart.map((a) => a.passives));
        const passivesMax = Math.max(...chart.map((a) => a.passives));
        const passivesSum = chart.reduce((a, b) => a + Number(b.passives), 0);
        const passivesAverage = passivesSum / chart.length;
        const totalPassives = chart.reduce((a, b) => a + Number(b.passives), 0);
        const pieChart = {
          totalDetractors,
          totalPromoters,
          totalPassives,
        };

        return {
          totalRatings: ratingData.length,
          ratingId,
          ratingName,
          chart,
          chartTitle: isComparison
            ? `${ratingName} Breakdown, Month by Month comparison`
            : `${ratingName} Breakdown, Day by Day Comparison`,
          pieChart,
          //Promoters
          promotersMin,
          promotersMax,
          totalPromoters,
          promotersAverage,
          //Detractors
          detractorsMin,
          detractorsMax,
          totalDetractors,
          detractorsAverage,

          //Passives
          passivesMin,
          passivesMax,
          totalPassives,
          passivesAverage,
        };
      });

      return {
        branchRatingsData,
        branchTotal: branchRatingsData.reduce((a, b) => a + b.totalRatings, 0),
        branchName: branches.find((a) => a.id === branchId)?.name,
        branchId,
      };
    })
    .sort((a, b) => b.branchTotal - a.branchTotal);

  return (
    <Box>
      {branchesData.map(
        ({ branchId, branchName, branchRatingsData }, branchIdex) => {
          const title = `${branchName} Branch`;
          return (
            <Box key={branchId}>
              <h2>{title}</h2>
              <Text>
                {branchRatingsData.length} rating
                {branchRatingsData.length !== 1 ? "s" : ""}
              </Text>
              {branchRatingsData.map((data, index) => {
                return (
                  <RatingsBreakdownTable
                    ratingData={data}
                    branchIndex={branchIdex}
                    index={index}
                    branchName={branchName}
                    reportIndex={reportIndex}
                  />
                );
              })}
            </Box>
          );
        }
      )}
    </Box>
  );
};

export default RatingsBreakdown;
