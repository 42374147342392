import {
  Box,
  HStack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { formatNumber } from "../../../store/helpers";
import RatingsBreakdownBarChart from "../charts/RatingsBreakdownBarChart";
import RatingsBreakdownPieChart from "../charts/RatingsBreakdownPieChart";

const RatingsBreakdownTable = ({
  index,
  branchIndex,
  reportIndex,
  branchName,
  ratingData,
}) => {
  const {
    ratingId,
    ratingName,
    chart,
    chartTitle,
    totalRatings,
    promotersMin,
    promotersMax,
    totalPromoters,
    promotersAverage,

    //detractor
    detractorsMin,
    detractorsMax,
    totalDetractors,
    detractorsAverage,

    //passives
    passivesMin,
    passivesMax,
    totalPassives,
    passivesAverage,
    pieChart,
  } = ratingData;
  const title = `${reportIndex || ""}.${branchIndex + 1}.${
    index + 1
  }. ${ratingName}`;
  return (
    <Box className="reportContainer" mt="20px" key={ratingId}>
      <h3>{title}</h3>
      <Tag>
        <p className="branchTag">{branchName} Branch</p>
      </Tag>
      {totalRatings ? (
        <>
          {" "}
          <Table className="stripedTable" size="sm">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th borderRight="1px solid #dadce0">Type</Th>
                <Th borderRight="1px solid #dadce0">Total</Th>
                <Th borderRight="1px solid #dadce0">Average</Th>
                <Th borderRight="1px solid #dadce0">Min</Th>
                <Th borderRight="1px solid #dadce0">Max</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{index + 1}</Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>Promoters</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{formatNumber(totalPromoters)}</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{formatNumber(promotersAverage)}</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{formatNumber(promotersMin)}</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{formatNumber(promotersMax)}</Text>
                </Td>
              </Tr>

              {/* passives */}
              <Tr>
                <Td>{index + 2}</Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>Passives</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{formatNumber(totalPassives)}</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{formatNumber(passivesAverage)}</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{formatNumber(passivesMin)}</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{formatNumber(passivesMax)}</Text>
                </Td>
              </Tr>

              {/* detractors */}
              <Tr>
                <Td>{index + 3}</Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>Detractors</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{formatNumber(totalDetractors)}</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{formatNumber(detractorsAverage)}</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{formatNumber(detractorsMin)}</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{formatNumber(detractorsMax)}</Text>
                </Td>
              </Tr>
              <Tr>
                <Td colSpan={6}>
                  <Box mt="20px" mb="20px">
                    <HStack>
                      <Box w="30%">
                        <RatingsBreakdownPieChart
                          title={`Ticket Management Comparison`}
                          chart={pieChart}
                          chartHeight="200px"
                        />
                      </Box>
                      <Box w="70%" key={3423}>
                        <RatingsBreakdownBarChart
                          chart={chart}
                          title={chartTitle}
                        />
                      </Box>
                    </HStack>
                  </Box>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </>
      ) : (
        <>
          {" "}
          <Text fontSize="sm">No data available for this report</Text>
        </>
      )}
    </Box>
  );
};

export default RatingsBreakdownTable;
