import { Center, Spinner, VStack, Text, Progress } from "@chakra-ui/react";
import { NUM_PREV_MONTHS } from "../store/constants";
import { useSelector } from "react-redux";

const RatingsLoader = ({ isComparison = false }) => {
  const { comparisonDataMonth } = useSelector((state) => state.ratingsReducer);
  const progressPct = ((comparisonDataMonth / NUM_PREV_MONTHS) * 100).toFixed(
    2
  );
  console.log(progressPct, "pct");
  return (
    <Center py="10em">
      <VStack>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="var(--blue)"
          size="xl"
          display="flex"
          justifyContent="center"
          alignContent="center"
        />
        {/* <Progress value={Number(progressPct)} /> */}
        {isComparison && (
          <Text color="gray.500">
            Fetching data for month {comparisonDataMonth} of {NUM_PREV_MONTHS}
          </Text>
        )}
      </VStack>
    </Center>
  );
};

export default RatingsLoader;
