// Auth
export const FETCH_CLOUD_ORGANIZATIONS = "FETCH_CLOUD_ORGANIZATIONS";

//Add all action types here
export const ACTION_TYPES = Object.freeze({
  UPDATE_REPORT_DURATION: "UPDATE_REPORT_DURATION",
  UPDATE_APP_STATUS: "UPDATE_APP_STATUS",
  SET_AUTH_LOADING: "SET_AUTH_LOADING",

  //ratingsReducer
  GET_RATINGS: "GET_RATINGS",
  SET_RATINGS_LOADING: "SET_RATINGS_LOADING",
  GET_COMPARISON_DATA: "GET_COMPARISON_DATA",
  UPDATE_COMPARISON_DATA: "UPDATE_COMPARISON_DATA",
  UPDATE_COMPARISON_DATA_MONTH: "UPDATE_COMPARISON_DATA_MONTH",
  GET_FORM: "GET_FORM",

  //authReducer
  UPDATE_VERIFY_STATUS: "UPDATE_VERIFY_STATUS",
  SET_USER_ID: "SET_USER_ID",
  SET_USER_DATA: "SET_USER_DATA",
  GET_USER_PERMISSIONS: "GET_USER_PERMISSIONS",

  //permissionsreducer
  GET_IAM_PERMISSIONS: "GET_IAM_PERMISSIONS",
  GET_IAM_ROLES: "GET_IAM_ROLES",
});
