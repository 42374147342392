import dayjs from "dayjs";
import { useSelector } from "react-redux";
import FeedbackEngagementTable from "../tables/FeedbackEngagementTable";
import { Box } from "@chakra-ui/react";
import {
  generateDateArray,
  generatePrevMonthsArray,
} from "../../../store/helpers";
import _ from "lodash";

const FeedbackEngagement = ({ data, reportIndex, isComparison }) => {
  const { dateRange, branches, workWeek, branchId, organizationId } =
    useSelector((state) => state.defaultReducer);
  //   data = data.filter(
  //     (a) =>
  //       dayjs(a.date, "DD-MM-YYYY").unix() >= dateRange[0] &&
  //       dayjs(a.date, "DD-MM-YYYY").unix() <= dateRange[1]
  //   );

  if (!isComparison)
    data = data.filter((a) => {
      const dateString = a.date.split("-");
      const date = new Date(
        parseInt(dateString[2]),
        parseInt(dateString[1]) - 1,
        parseInt(dateString[0])
      );
      const timestamp = date.getTime() / 1000;
      return timestamp >= dateRange[0] && timestamp <= dateRange[1];
    });
  const branchesClone = _.cloneDeep(branches);
  const orgBranches = branchesClone
    .filter(
      (a) => a.organizationId === organizationId && Number(a.cfReportStatus)
    )
    .filter((a) => !branchId.length || (branchId.length && a.id === branchId));
  const branchesMap = orgBranches.reduce((acc, branch) => {
    acc[branch.id] = [];
    return acc;
  }, {});

  data.forEach((a) => {
    const branchId = a.branchId;

    if (Array.isArray(branchesMap[branchId])) {
      branchesMap[branchId].push(a);
    }
  });

  const branchesData = Object.keys(branchesMap)
    .map((branchId) => {
      const branchData = branchesMap[branchId];
      const datesBetween = generateDateArray(dateRange[0], dateRange[1]);
      let chart = [];
      if (isComparison) {
        const monthsArray = generatePrevMonthsArray(dateRange[0]);
        const monthlyChart = monthsArray.map((monthStart) => {
          const monthData = branchData.filter((a) => {
            const d = a.date.split("-");
            return `${d[1]}-${d[2]}` === dayjs(monthStart).format("MM-YYYY");
          });
          const feedback =
            monthData.reduce((acc, a) => acc + a.feedback, 0) || 0;
          const visitors =
            monthData.reduce((acc, a) => acc + a.visitors, 0) || 0;
          const feedbackEngagement =
            Number(((feedback / visitors) * 100).toFixed(2)) || 0;
          return {
            date: dayjs(monthStart).format("MM-YYYY"),
            feedback,
            visitors,
            feedbackEngagement,
          };
        });
        chart = monthlyChart;
      } else {
        const dailyChart = datesBetween
          .map((fullDate) => {
            let date = dayjs(fullDate).date();
            const dayData = branchData.filter(
              (a) => Number(a.date.split("-")[0]) === date
            );
            const feedback =
              dayData.reduce((acc, a) => acc + a.feedback, 0) || 0;
            const visitors =
              dayData.reduce((acc, a) => acc + a.visitors, 0) || 0;
            const feedbackEngagement =
              Number(((feedback / visitors) * 100).toFixed(2)) || 0;
            if (workWeek.includes(dayjs(fullDate).day()))
              return {
                date,
                feedback,
                visitors,
                feedbackEngagement,
              };
            return null;
          })
          .filter(Boolean);
        chart = dailyChart;
      }

      //visitors
      const totalVisitors = chart.reduce((acc, a) => acc + a.visitors, 0);
      const minVisitors = Math.min(...chart.map((a) => a.visitors));
      const maxVisitors = Math.max(...chart.map((a) => a.visitors));
      const avgVisitors = totalVisitors / chart.length;

      //feedback
      const totalFeedback = chart.reduce((acc, a) => acc + a.feedback, 0);
      const minFeedback = Math.min(...chart.map((a) => a.feedback));
      const maxFeedback = Math.max(...chart.map((a) => a.feedback));
      const avgFeedback = totalFeedback / chart.length;

      return {
        branchId,
        branchName: branches.find((a) => a.id === branchId).name,
        chart,
        chartTitle: isComparison
          ? `Feedback Engagement, Month by Month comparison`
          : `Feedback Engagement, Day by Day Comparison`,
        //visitors
        totalVisitors,
        minVisitors,
        maxVisitors,
        avgVisitors,
        //feedback
        totalFeedback,
        minFeedback,
        maxFeedback,
        avgFeedback,
      };
    })
    .sort((a, b) => b.totalVisitors - a.totalVisitors);

  return (
    <Box>
      {branchesData.map((data, branchIdex) => {
        const { branchName } = data;
        const title = `${branchName} Branch`;
        return (
          <>
            <h2>{title}</h2>
            <FeedbackEngagementTable
              data={data}
              index={0}
              reportIndex={reportIndex}
              branchIndex={branchIdex}
              branchName={branchName}
            />
          </>
        );
      })}
    </Box>
  );
};

export default FeedbackEngagement;
