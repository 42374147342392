export const version = "1.8.22";
export const ARRIVAL_RATES_FREQUENCIES = Object.freeze({ HOUR: "0", DAY: "1" });
export const LEVELS = Object.freeze({ ORGANIZATION: "0", BRANCH: "1" });
export const completed = 3;
export const noShow = 2;
export const transferred = 1;
export const TICKET_STATUS = Object.freeze({
  COMPLETED: 3,
  NO_SHOW: 2,
  TRANSFERRED: 1,
});

export const REPORT_RANGES = Object.freeze({
  MONTH: "month",
  WEEK: "week",
  DAY: "day",
});

export const APP_STATUSES = Object.freeze({
  LOGGED_IN: 1,
  PEDING_LOGIN: 2,
  LOGGED_OUT: 0,
});

export const NUM_PREV_MONTHS = 12;

export const navLinks = Object.freeze([
  {
    name: "month",
    links: [
      {
        name: "Organization",
        links: [
          {
            name: "NPS Rating",
            link: "/monthly/organization/nps-rating/",
          },
          {
            name: "NPS Rating Comparison",
            link: "/monthly/comparison/organization/nps-rating/",
          },
          {
            name: "Ratings Breakdown",
            link: "/monthly/organization/ratings-breakdown/",
          },
          {
            name: "Ratings Breakdown Comparison",
            link: "/monthly/comparison/organization/ratings-breakdown/",
          },
          {
            name: "Feedback Engagement",
            link: "/monthly/organization/feedback-engagement/",
          },
          {
            name: "Feedback Engagement Comparison",
            link: "/monthly/comparison/organization/feedback-engagement/",
          },

          {
            name: "NPS Rating Per Agent",
            link: "/monthly/organization/nps-rating-per-agent/",
          },
          {
            name: "NPS Rating Per Agent Comparison",
            link: "/monthly/comparison/organization/nps-rating-per-agent/",
          },
        ],
      },
      {
        name: "Branches",
        links: [
          {
            name: "NPS Rating Per Agent",
            link: "/monthly/branches/nps-rating-per-agent/",
          },
          {
            name: "NPS Rating Per Agent Comparison",
            link: "/monthly/comparison/branches/nps-rating-per-agent/",
          },
          {
            name: "NPS Rating",
            link: "/monthly/branches/nps-rating/",
          },
          {
            name: "NPS Rating Comparison",
            link: "/monthly/comparison/branches/nps-rating/",
          },
          {
            name: "Ratings Breakdown",
            link: "/monthly/branches/ratings-breakdown/",
          },
          {
            name: "Ratings Breakdown Comparison",
            link: "/monthly/comparison/branches/ratings-breakdown/",
          },
          {
            name: "Feedback Engagement",
            link: "/monthly/branches/feedback-engagement/",
          },
          {
            name: "Feedback Engagement Comparison",
            link: "/monthly/comparison/branches/feedback-engagement/",
          },
        ],
      },
    ],
  },
]);

export const PRODUCT_SERVICE_ID = "28878047-ac78-40d0-87b5-35d632b338dd";
