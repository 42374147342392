import {
  Box,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { formatNumber } from "../../../store/helpers";
import NPSRatingLineChart from "../charts/NPSRatingLineChart";

const NPSRatingTable = ({
  index,
  branchIndex,
  reportIndex,
  branchName,
  ratingData,
}) => {
  const {
    ratingName,
    chart,
    average, min, max,
    totalRatings,
    chartTitle
  } = ratingData;
  const title = `${reportIndex || ""}.${branchIndex + 1}.${
    index + 1
  }. ${ratingName} NPS Rating`;
  return (
    <Box className="reportContainer" mt="20px">
      <h3>{title}</h3>
      <Tag>
        <p className="branchTag">{branchName} Branch</p>
      </Tag>
      {totalRatings ? (
        <>
          {" "}
          <Table className="stripedTable" size="sm">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th borderRight="1px solid #dadce0">Total</Th>
                <Th borderRight="1px solid #dadce0">Average</Th>
                <Th borderRight="1px solid #dadce0">Min</Th>
                <Th borderRight="1px solid #dadce0">Max</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{index + 1}</Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{formatNumber(totalRatings)}</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{average.toFixed(2)}%</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{min.toFixed(2)}%</Text>
                </Td>
                <Td borderRight="1px solid #dadce0">
                  <Text>{max.toFixed(2)}%</Text>
                </Td>
              </Tr>
              <Tr>
                <Td colSpan={8}>
                  <Box mt="20px" mb="20px">
                    <NPSRatingLineChart
                      chart={chart}
                      title={chartTitle}
                    />
                  </Box>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </>
      ) : (
        <>
          {" "}
          <Text fontSize="sm">No data available for this report</Text>
        </>
      )}
    </Box>
  );
};

export default NPSRatingTable;
