import { Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import FilterForm from "../../components/FilterForm";
import _ from "lodash";
import FeedbackEngagement from "../../components/organization/reports/FeedbackEngagement";
import RatingsLoader from "../../components/RatingsLoader";

const OrganizationFeedbackEngagement = ({
  showFilterForm = true,
  reportIndex = 1,
  isComparison = false
}) => {
  const { ratingsLoading, visitors } = useSelector(
    (state) => state.ratingsReducer
  );

  let visitorsClone = _.cloneDeep(visitors);
  return (
    <Box>
      {showFilterForm && <FilterForm isComparison={isComparison} />}
      {ratingsLoading ? (
        <RatingsLoader isComparison={isComparison} />
      ) : (
        <>
          {!_.isEmpty(visitorsClone) ? (
            <>
              <h1>Feedback Engagement {isComparison && "Comparison"}</h1>
              <FeedbackEngagement
                data={visitorsClone}
                isComparison={isComparison}
                reportIndex={reportIndex}
              />
            </>
          ) : (
            <>
              <Box>No data available in this search</Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default OrganizationFeedbackEngagement;
