import { connect } from "react-redux";
import { NavLink } from "react-router-dom"; // Use the appropriate router package for your application
import { useSelector } from "react-redux";
import { Box, Button, Divider, Text, VStack } from "@chakra-ui/react";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { updateAppStatus } from "../../store/actions/authActions";

import { APP_STATUSES, navLinks } from "../../store/constants";

function Nav() {
  const { reportRange } = useSelector((state) => state.defaultReducer);
  const dispatch = useDispatch();

  const logout = () => {
    Cookies.remove("accessToken");
    Cookies.remove("userId");
    dispatch(updateAppStatus(APP_STATUSES.LOGGED_OUT));
    window.location.replace(
      `https://auth.averly.com.na?origin=${window.location.href}`
    );
  };
  return (
    <Box className="navigation">
      <VStack spacing={4} align="stretch" pt={10}>
        {navLinks
          .find((a) => a.name === reportRange)
          ?.links.map((a, i) => {
            return (
              <Box key={i} mt="10px">
                <Text fontSize="sm" fontWeight="bold" color="gray.500">
                  {a.name}
                </Text>
                <VStack spacing={2} align="stretch" pt={2}>
                  {a.links.map((b, j) => {
                    return (
                      <NavLink key={j} to={b.link} activeClassName="active">
                        {b.name}
                      </NavLink>
                    );
                  })}
                </VStack>
              </Box>
            );
          })}
        <Divider />
        <Button
          _active={{ bg: "var(--blue)" }}
          _hover={{ bg: "var(--blue)", color: "#fff" }}
          onClick={logout}
        >
          Logout
        </Button>
      </VStack>
    </Box>
  );
}

export default connect(null, null)(Nav);
