import { ACTION_TYPES } from "../actions/types";
import dayjs from "dayjs";
import visitors from "../../visitors.json";

const initialState = {
  dateRange: [
    dayjs().subtract(1, "month").startOf("month").unix(),
    dayjs().subtract(1, "month").endOf("month").unix(),
  ],
  ratings: [],
  comparisonData: [],
  comparisonDataMonth: 0,
  visitors: visitors,
  ratingsLoading: false,
  form: {},
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.GET_RATINGS:
      return {
        ...state,
        ratings: action.payload,
      };

    case ACTION_TYPES.SET_RATINGS_LOADING:
      return {
        ...state,
        ratingsLoading: action.payload,
      };
    case ACTION_TYPES.GET_COMPARISON_DATA:
      return {
        ...state,
        comparisonData: action.payload,
      };
    case ACTION_TYPES.UPDATE_COMPARISON_DATA:
      return {
        ...state,
        comparisonData: [...state.comparisonData, ...action.payload],
      };
    case ACTION_TYPES.UPDATE_COMPARISON_DATA_MONTH:
      return {
        ...state,
        comparisonDataMonth: action.payload,
      };

    //set form
    case ACTION_TYPES.GET_FORM:
      return {
        ...state,
        form: action.payload,
      };

    default:
      return state;
  }
}
