import React from "react";
import _ from "lodash";

import { Box, Heading } from "@chakra-ui/react";

import FilterForm from "../components/FilterForm";

import { useSelector } from "react-redux";
import { formatNumber } from "../store/helpers";

const Dashboard = () => {
  const { ratings } = useSelector((state) => state.defaultReducer);
  const ratingsClone = _.cloneDeep(ratings);

  return (
    <Box>
      <Heading size="md">Welcome to the CF Analytics Dashboard</Heading>
      <FilterForm />
      <Box mt="20px">Results: {formatNumber(ratingsClone.length)} ratings</Box>
    </Box>
  );
};

export default Dashboard;
