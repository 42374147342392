import { Box, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import NPSRatingPerAgentTable from "../tables/NPSRatingPerAgentTable";
import _ from "lodash";

const NPSRatingPerAgent = ({ data, reportIndex, isComparison }) => {
  const { branches, branchId, organizationId } = useSelector(
    (state) => state.defaultReducer
  );
  const { form } = useSelector((store) => store.ratingsReducer);

  const branchesClone = _.cloneDeep(branches);
  const orgBranches = branchesClone
    .filter(
      (a) => a.organizationId === organizationId && Number(a.cfReportStatus)
    )
    .filter((a) => !branchId.length || (branchId.length && a.id === branchId));
  const branchesMap = orgBranches.reduce((acc, branch) => {
    acc[branch.id] = [];
    return acc;
  }, {});

  data.forEach((a) => {
    const branchId = a.branchId;

    if (Array.isArray(branchesMap[branchId])) {
      branchesMap[branchId].push(a);
    }
  });

  const branchesData = Object.keys(branchesMap).map((branchId) => {
    const branchData = branchesMap[branchId];
    const branchName = branches.find((a) => a.id === branchId)?.name;

    const agentsMap = {};
    branchData.forEach((a) => {
      if (!agentsMap[a.ticket.manage.userId]) {
        agentsMap[a.ticket.manage.userId] = { name: a.ticket.manage.fullname };
      }
    });

    const agentsData = Object.keys(agentsMap)
      .map((agentId) => {
        const agentName = agentsMap[agentId].name;
        const agentData = branchData.filter(
          (a) => a.ticket.manage.userId === agentId
        );
        // All form inputs of type rating
        const ratingsMap = form.fields
          .filter((a) => a.type === "rating")
          .reduce((acc, { id }) => {
            acc[id] = [];
            return acc;
          }, {});

        agentData.forEach(({ ratings, created }) => {
          ratings.forEach((a) => {
            if (Array.isArray(ratingsMap[a.id])) {
              ratingsMap[a.id].push({ ...a, created });
            }
          });
        });

        const agentRatings = Object.keys(ratingsMap).map((ratingId) => {
          const ratingData = ratingsMap[ratingId];
          const ratingName = form.fields.find(
            (a) => a.id === ratingId
          )?.shortLabel;
          const promoters = ratingData.filter((a) => a.value >= 9).length;
          const detractors = ratingData.filter((a) => a.value <= 6).length;
          const passives = ratingData.filter(
            (a) => a.value >= 7 && a.value <= 8
          ).length;
          const promotersPct = (promoters / ratingData.length) * 100;
          const detractorsPct = (detractors / ratingData.length) * 100;
          const passivesPct = (passives / ratingData.length) * 100;
          const NPSScore = promotersPct - detractorsPct;
          const average =
            ratingData.reduce((a, b) => a + b.value, 0) / ratingData.length;
          const min = Math.min(...ratingData.map((a) => a.value));
          const max = Math.max(...ratingData.map((a) => a.value));
          return {
            totalRatings: ratingData.length,
            promoters,
            detractors,
            passives,
            NPSScore,
            ratingId,
            ratingName,
            promotersPct,
            detractorsPct,
            passivesPct,
            average,
            min,
            max,
          };
        });

        return {
          agentId,
          agentName,
          agentRatings,
          totalRatings: agentData.length,
        };
      })
      .sort((a, b) => {
        if (b.totalRatings - a.totalRatings !== 0) {
          return b.totalRatings - a.totalRatings;
        }
        return a.agentName.localeCompare(b.agentName);
      });

    return {
      branchId,
      agentsData,
      branchName,
    };
  });

  return (
    <Box>
      {branchesData.map(({ branchId, branchName, agentsData }, branchIdex) => {
        const title = `${branchName} Branch`;
        return (
          <Box key={branchId}>
            <h2>{title}</h2>
            <Text>{agentsData.length} agents</Text>
            {agentsData.map((data, index) => {
              return (
                <NPSRatingPerAgentTable
                  key={data.agentId}
                  agentData={data}
                  branchIndex={branchIdex}
                  index={index}
                  branchName={branchName}
                  reportIndex={reportIndex}
                />
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};

export default NPSRatingPerAgent;
