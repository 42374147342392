import { useSelector } from "react-redux";
import {
  generateDateArray,
  generatePrevMonthsArray,
} from "../../../store/helpers";
import dayjs from "dayjs";
import NPSRatingTable from "../tables/NPSRatingTable";
import { Box, Text } from "@chakra-ui/react";

const NPSRating = ({ data, reportIndex, isComparison }) => {
  const { dateRange, workWeek } = useSelector((state) => state.defaultReducer);
  const { form } = useSelector((store) => store.ratingsReducer);

  // All form inputs of type rating
  const ratingsMap = form.fields
    .filter((a) => a.type === "rating")
    .reduce((acc, { id }) => {
      acc[id] = [];
      return acc;
    }, {});

  data.forEach(({ ratings, created }) => {
    ratings.forEach((a) => {
      if (Array.isArray(ratingsMap[a.id])) {
        ratingsMap[a.id].push({ ...a, created });
      }
    });
  });
  const orgRatingsData = Object.keys(ratingsMap).map((ratingId) => {
    const ratingData = ratingsMap[ratingId];
    const ratingName = form.fields.find((a) => a.id === ratingId)?.shortLabel;

    //monthly chart

    let chart = [];
    if (isComparison) {
      const monthsArray = generatePrevMonthsArray(dateRange[0]);
      const monthlyChart = monthsArray.map((monthStart) => {
        const monthData = ratingData.filter(
          (a) =>
            dayjs.unix(a.created).format("MM-YYYY") ===
            dayjs(monthStart).format("MM-YYYY")
        );
        const promoters = monthData.filter((a) => a.value >= 9).length;
        const detractors = monthData.filter((a) => a.value <= 6).length;
        const promotersPct = (promoters / monthData.length) * 100 || 0;
        const detractorsPct = (detractors / monthData.length) * 100 || 0;

        const nps = promotersPct - detractorsPct || 0;

        return {
          date: dayjs(monthStart).format("MM-YYYY"),
          nps: Number(nps.toFixed(2)),
        };
      });
      chart = monthlyChart;
    } else {
      const datesBetween = generateDateArray(dateRange[0], dateRange[1]);
      const dailyChart = datesBetween
        .map((fullDate) => {
          let date = dayjs(fullDate).date();
          const dayData = ratingData.filter(
            (a) => dayjs.unix(a.created).date() === date
          );
          const promoters = dayData.filter((a) => a.value >= 9).length;
          const detractors = dayData.filter((a) => a.value <= 6).length;
          const promotersPct = (promoters / dayData.length) * 100 || 0;
          const detractorsPct = (detractors / dayData.length) * 100 || 0;

          const nps = promotersPct - detractorsPct || 0;
          if (workWeek.includes(dayjs(fullDate).day())) {
            return {
              date,
              nps: Number(nps.toFixed(2)),
            };
          }
          return null;
        })
        .filter(Boolean);
      chart = dailyChart;
    }

    const min = Math.min(...chart.map((a) => a.nps)) || 0;
    const max = Math.max(...chart.map((a) => a.nps)) || 0;
    //sum nps
    const sum = chart.reduce((a, b) => a + Number(b.nps), 0) || 0;
    const average = sum / chart.length;

    return {
      totalRatings: ratingData.length,
      ratingId,
      ratingName,
      average,
      min,
      max,
      chart,
      chartTitle: isComparison
        ? `${ratingName}, Month by Month comparison`
        : `${ratingName}, Day by Day Comparison`,
    };
  });

  return (
    <Box>
      <Box>
        <h2>Entire Organization</h2>
        <Text>
          {orgRatingsData.length} rating
          {orgRatingsData.length !== 1 ? "s" : ""}
        </Text>
        {orgRatingsData.map((data, index) => {
          return (
            <NPSRatingTable
              key={data.ratingId}
              ratingData={data}
              orgIndex={1}
              index={index}
              subtitle={"Entire Organization"}
              reportIndex={reportIndex}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default NPSRating;
