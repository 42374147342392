import { Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import FilterForm from "../../components/FilterForm";
import _ from "lodash";
import RatingsBreakdown from "../../components/organization/reports/RatingsBreakdown";
import RatingsLoader from "../../components/RatingsLoader";

const OrganizationRatingsBreakdown = ({
  showFilterForm = true,
  reportIndex = 1,
  isComparison = false,
}) => {
  const { ratings, ratingsLoading, comparisonData } = useSelector(
    (state) => state.ratingsReducer
  );
  const { branchId } = useSelector((state) => state.defaultReducer);
  let ratingsClone = _.cloneDeep(ratings);
  const comparisonDataClone = _.cloneDeep(comparisonData);
  return (
    <Box>
      {showFilterForm && <FilterForm isComparison={isComparison} />}
      {ratingsLoading ? (
        <RatingsLoader isComparison={isComparison} />
      ) : (
        <>
          {(isComparison && !_.isEmpty(comparisonDataClone)) ||
          (!_.isEmpty(ratingsClone) && !isComparison) ? (
            <>
              <h1>Ratings Breakdown {isComparison && "Comparison"}</h1>
              <RatingsBreakdown
                data={isComparison ? comparisonData : ratingsClone}
                isComparison={isComparison}
                reportIndex={reportIndex}
              />
            </>
          ) : (
            <>
              <Box>No data available in this search</Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default OrganizationRatingsBreakdown;
