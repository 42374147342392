import { Box, Select, Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { updateOrganizationId } from "../../store/actions/defaultAction";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import useRoles from "../../hooks/useRoles";

const OrganizationSelection = () => {
  const dispatch = useDispatch();
  const { organizationId, organizations } = useSelector(
    (store) => store.defaultReducer
  );

  const { isSuperAdmin, organizationId: userOrganizationId } = useRoles();
  useEffect(() => {
    if (!isSuperAdmin) {
      dispatch(updateOrganizationId(userOrganizationId));
    }
  }, [isSuperAdmin]);

  return isSuperAdmin ? (
    <Box>
      <Text fontSize={14}>Select Organization</Text>
      <Select
        placeholder="Select organization"
        disabled={!isSuperAdmin}
        value={organizationId}
        onChange={(e) => dispatch(updateOrganizationId(e.target.value))}
      >
        {organizations?.map((a, i) => (
          <option key={i} value={a.id}>
            {a.name}
          </option>
        ))}
      </Select>
    </Box>
  ) : (
    <></>
  );
};

export default OrganizationSelection;
