import { fetchData } from "./defaultAction";
import { ACTION_TYPES } from "./types";

//GET_IAM_ROLES
export const getIamRoles = () => async (dispatch) => {
  const endpoint = "/iam/roles";
  const payload = {};
  const actionType = ACTION_TYPES.GET_IAM_ROLES;
  const singleResponse = 0;
  await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
};
