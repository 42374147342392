import { combineReducers } from "redux";
import defaultReducer from "./defaultReducer";
import authReducer from "./authReducer";
import ratingsReducer from "./ratingsReducer";
import permissionsReducer from "./permissionsReducer";

const reducer = combineReducers({
  defaultReducer,
  authReducer,
  ratingsReducer,
  permissionsReducer,
});

export default reducer;
