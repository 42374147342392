import dayjs from "dayjs";
import { fetchData } from "./defaultAction";
import { ACTION_TYPES } from "./types";
import { generatePrevMonthsArray } from "../helpers";

//GET_RATINGS
export const getRatings =
  (organizationId, branchId, dateRange = null) =>
  async (dispatch) => {
    const endpoint = "/cf/ratings";
    const payload = {
      organizationId,
      branchId,
      createdFrom: dateRange
        ? dateRange[0]
        : dayjs().subtract(1, "month").startOf("month").unix(),
      createdTo: dateRange ? dateRange[1] : dayjs().unix(),
    };
    const actionType = ACTION_TYPES.GET_RATINGS;
    const singleResponse = 0;
    dispatch(setRatingsLoading(true));
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
    dispatch(setRatingsLoading(false));
  };

export const getComparisonData =
  (organizationId, branchId, dateRange) => async (dispatch) => {
    const endpoint = "/cf/ratings";
    const monthsArray = generatePrevMonthsArray(dateRange[0]);

    dispatch(setRatingsLoading(true));
    dispatch(resetComparisonData());
    for (let i = 0; i < monthsArray.length; i++) {
      const createdFrom = dayjs(monthsArray[i]).unix();
      const createdTo = dayjs(monthsArray[i]).endOf("month").unix();
      const payload = {
        organizationId,
        branchId,
        createdFrom,
        createdTo,
      };
      const actionType = ACTION_TYPES.UPDATE_COMPARISON_DATA;
      const singleResponse = 0;
      dispatch(updateComparisonDataMonth(i + 1));
      await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
    }
    dispatch(setRatingsLoading(false));
  };

//SET_RATINGS_LOADING
export const setRatingsLoading = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_RATINGS_LOADING,
    payload,
  });
};

//UPDATE_COMPARISON_DATA
export const updateComparisonData = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_COMPARISON_DATA,
    payload,
  });
};

//RESET_COMPARISON_DATA
export const resetComparisonData = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.GET_COMPARISON_DATA,
    payload: [],
  });
};

//UPDATE_COMPARISON_DATA_MONTH
export const updateComparisonDataMonth = (payload) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_COMPARISON_DATA_MONTH,
    payload,
  });
};


//GET_RATINGS
export const getForm =
  (organizationId) =>
  async (dispatch) => {
    const endpoint = "/fb/forms";
    const payload = {
      organizationId,
    };
    const actionType = ACTION_TYPES.GET_FORM;
    const singleResponse = 1;
    dispatch(setRatingsLoading(true));
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
    dispatch(setRatingsLoading(false));
  };
